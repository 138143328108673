import { useStaticQuery, graphql } from 'gatsby'
import React, { useState, useEffect, useRef } from 'react'
import CrossfadeImage from 'react-crossfade-image'

const images = [
  require('../../content/assets/app-screenshots/Login_Screen.svg'),
	require('../../content/assets/app-screenshots/Assessment_Screen.svg'),
	require('../../content/assets/app-screenshots/Home_Screen.png'),
  require('../../content/assets/app-screenshots/Insights_Screen.svg')
]

const SlideShow = props => {	
	const [state, setState] = useState({
		activeSlide: 0,
		// translate: 400,
		transition: 0.45,
	})

	const { activeSlide, translate, transition } = state

	const autoPlayRef = useRef()
	const transitionRef = useRef()
	const resizeRef = useRef()

	useEffect(() => {
		autoPlayRef.current = nextSlide
		transitionRef.current = smoothTransition
		resizeRef.current = handleResize
	})


  useEffect(() => {
    const play = () => {
      autoPlayRef.current()
		}
		
		const smooth = () => {
			transitionRef.current()
		}

		const resize = () => {
			resizeRef.current()
		}

		const interval = setInterval(play, 3 * 1000)
		const transitionEnd = window.addEventListener('transitionend', smooth)
		const onResize = window.addEventListener('resize', resize)

    return () => {
			clearInterval(interval)
			window.removeEventListener('transitionend', transitionEnd)
			window.removeEventListener('resize', onResize)
		}
		
  }, [])

	useEffect(() => {
    if (transition === 0) setState({ ...state, transition: 0.45 })
	}, [transition])
	
	const handleResize = () => {
		setState({ ...state, translate: 400, transition: 0 })
	}

  const smoothTransition = () => {
    let _slides = []

    setState({
      ...state,
      _slides,
      transition: 0,
      translate: 400
    })
	}
	
	const nextSlide = () => {
    if (activeSlide === images.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeSlide: 0
      })
    }

    setState({
      ...state,
      activeSlide: activeSlide + 1,
      translate: (activeSlide + 1) * 400
    })
	}
	
  return (
    <div className="slider-container">
      <div className="slider-content">
				<CrossfadeImage 
					style={{
						height: "100%",
						width: "100%",
						maxwidth: "400px",
						transition: 'ease-in 2s'
					}}
					src={images[activeSlide]}
				/>
			</div>
    </div>
  )
}

export default SlideShow