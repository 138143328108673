import React, {useState} from "react"
import { Link, graphql } from "gatsby"
import { Spring } from "react-spring/renderprops";
import { OutboundLink } from "gatsby-plugin-google-gtag"
import VisibilitySensor from '../components/visibilitySensor'
import PopupForm from '../components/popupform'
import Layout from "../layouts/layout"
import SEO from "../components/seo"

import SlideShow from '../components/imageCarousel'

const ProductPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    if (isPopupOpen) {
      setIsPopupOpen(false);
    } else {
      setIsPopupOpen(true);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    togglePopup();
    typeof window !== "undefined" && window.gtag("event", "click", {   
      'event_category': "Get Started",
      'event_label': "Demo Request",
      'value': 1 
    })
  }

    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Sunset Health Experience" />
        <PopupForm isPopupOpen={isPopupOpen} onClickPopup={togglePopup} />
        <section className="uni-section about-hero">
            <div className="uni-container flex-space-between flex-vertical-reverse">
              <div className="uni-col">
                <div className="uni-h-wrap">
                  <h1 className="uni-h1">Make your mental wellness a priority</h1>
                  <p className="uni-hero-p">Stop burnout, stress, and toxic behavioral changes in their tracks</p>
                  <button className="uni-cta-btn btn" onClick={handleClick}>Get Started</button>
                </div>
              </div>
              <img src={require('../../content/assets/hero-content/product.svg')} alt="Sunset Program image" className="uni-hero_img product_img" />
            </div>
        </section>
        <section className="uni-section bg-f0f6f9">
            <div className="uni-container flex-space-between prod-pave-vertical-center">
                <img src={require('../../content/assets/splash/seniors.jpg')} className="emp-prod_img" />
                <div className="uni-col about-col">
                  <div className="uni-h-wrap">
                    <h2 className="uni-h2">We're paving the way for a better future</h2>
                  </div>
                  <p>
                  As more environmental stresses continue to expand into our lives, further isolating and distracting us from our peripherals, we want to be part of the solution. 
                  We at Sunset Health aim to help people take the first step towards finding their happiness. At our core, we have created a solution that uses technology to help 
                  fix the problem of mental anguish that so many struggle with today. 
                  </p><br/>
                  <p>
                    We built Sunset to improve your mental health and performance via easy-to-use, personalized cognitive behavioral therapy. Dynamic and adaptable to meet the ever-changing 
                    presentation and drivers of burnout, such as isolation and work-life imbalance in a ‘work-from-home’ world.
                  </p>
                </div>
            </div>
        </section>
        <section className="uni-section border">
            <div className="uni-container prod-pave-vertical-center">
              <div className="uni-h-wrap">
                <h2 className="uni-h2 text-align-center">The science behind Sunset</h2>
                <p className="uni-p text-align-center">
                  Sunset’s cognitive behavioral therapy was developed to help you understand the stressors you have, how stressed you are, and, finally, how we can help you complete your stress cycles.
                </p><br/>
                <p className="uni-p text-align-center">In order to reduce our stress levels, we need to complete the stress response cycle:</p>
              </div>
              <div className="emp-stressor-content">
                <div className="ind-stress-wrap">
                  <VisibilitySensor once>
                    {({ isVisible }) => (
                      <Spring delay={200} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                        {({opacity, transform}) => (
                          <div className="ind-stressor-item" style={{opacity}}>
                            <div>
                                <h4 className="emp-h4 emp-feature-h4 text-align-center color-154a6e">Registering Stressor</h4>
                                <img src={require('../../content/assets/splash/stressors.jpg')} alt="Stressors image" className="ind-stress_img" />
                                <p className="emp-p">Stressors are environmental challenges or threats that affects you physically or psychologically.</p>
                                <p className="emp-p color-154a6e quote-italics">
                                  “A stressor could be a work conflict, paying taxes, an argument in a relationship or even a car alarm going off down the street.”
                                </p>
                            </div>
                          </div>
                          )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                    <VisibilitySensor once>
                      {({ isVisible }) => (
                        <Spring delay={200} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                          {({opacity, transform}) => (
                            <div className="ind-stressor-arrow" style={{opacity}}>
                              <img src={require('../../content/assets/icons/right-arrow.svg')} width={20} className="ind-stress_img" />
                            </div>
                            )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                    <VisibilitySensor once>
                      {({ isVisible }) => (
                        <Spring delay={300} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                          {({opacity, transform}) => (
                            <div className="ind-stressor-item" style={{opacity}}>
                              <div>
                                  <h4 className="emp-h4 emp-feature-h4 text-align-center color-154a6e">Developing Stress</h4>
                                  <img src={require('../../content/assets/splash/stresses.jpg')} alt="Stress out Image" className="ind-stress_img" />
                                  <p className="emp-p">Stress is the neurological and physiological response in the body to said stressors.</p>
                                  <p className="emp-p color-154a6e quote-italics">
                                    “You notice your heart rate increase, your mind start to race, and you begin worry about worst case scenarios.”
                                  </p>
                              </div>
                            </div>
                            )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                    <VisibilitySensor once>
                      {({ isVisible }) => (
                        <Spring delay={300} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                          {({opacity, transform}) => (
                            <div className="ind-stressor-arrow" style={{opacity}}>
                              <img src={require('../../content/assets/icons/right-arrow.svg')} width={20} className="ind-stress_img" />
                            </div>
                            )}
                        </Spring>
                      )}
                    </VisibilitySensor>
                    <VisibilitySensor once>
                      {({ isVisible }) => (
                        <Spring delay={400} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                          {({opacity, transform}) => (
                            <div className="ind-stressor-item" style={{opacity}}>
                                <div>
                                    <h4 className="emp-h4 emp-feature-h4 text-align-center color-154a6e">Complete Action</h4>
                                    <img src={require('../../content/assets/splash/positive-actions.jpg')} alt="Complete Action Image" className="ind-stress_img" />
                                    <p className="emp-p">Develop behaviors and habits that help your mind and body expel stress that has built up.</p>
                                    <p className="emp-p color-154a6e quote-italics">“Find what works best for you when you’re feeling different levels of stress and then schedule those activities into your day.”</p>
                                </div>
                            </div>
                        )}
                      </Spring>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
            </div>
        </section>
        <section className="uni-section border">
            <div className="uni-container">
                <div className="uni-h-wrap">
                    <h2 className="align-left">What can Sunset do for you</h2>
                </div>
                <div className="emp-feature-content">
                    <div className="ind-values-wrap">
                      <VisibilitySensor once>
                        {({ isVisible }) => (
                          <Spring delay={200} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                            {({opacity, transform}) => (
                              <div className="ind-values-item" style={{opacity}}>
                                  <img src={require('../../content/assets/icons/key.svg')} alt="Access your blocker" width="36" className="ind-values_img" />
                                  <div className="text-box-hold">
                                      <h4 className="emp-h4 emp-feature-h4">Assess your main blockers</h4>
                                      <p className="emp-p">Pinpoint, acknowledge, and assess how exhaustion, fatigue, cynicism, anxiety, depression, and isolation are impacting your mental health</p>
                                  </div>
                              </div>
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor>
                        <VisibilitySensor once>
                          {({ isVisible }) => (
                            <Spring delay={400} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                              {({opacity, transform}) => (
                                <div className="ind-values-item" style={{opacity}}>
                                  <img src={require('../../content/assets/icons/target.svg')} alt="Focus on what matters" width="36" className="ind-values_img" />
                                  <div className="text-box-hold">
                                      <h4 className="emp-h4 emp-feature-h4">Focus on what matters</h4>
                                      <p className="emp-p">Capture how often your blockers are impacting  your mental health so that you can overcome the biggest challenges first</p>
                                  </div>
                                </div>
                               )}
                            </Spring>
                          )}
                        </VisibilitySensor>
                        <VisibilitySensor once>
                          {({ isVisible }) => (
                            <Spring delay={600} to={{ opacity: isVisible ? 1 : 0, transform: isVisible ? "translateX(0)" : "translateX(-200px)", }}>
                              {({opacity, transform}) => (
                                <div className="ind-values-item" style={{opacity}}>
                                    <img src={require('../../content/assets/icons/clipboard.svg')} alt="Take the actions that work" width="36" className="ind-values_img" />
                                    <div className="text-box-hold">
                                        <h4 className="emp-h4 emp-feature-h4">Take the actions that work</h4>
                                        <p className="emp-p">Choose from a variety of recommended cognitive behavioral exercises selected to fit your mental health needs</p>
                                    </div>
                                </div>
                            )}
                          </Spring>
                        )}
                      </VisibilitySensor>
                    </div>
                </div>
            </div>
        </section>
        <section className="uni-section bg-f0f6f9">
            <div className="uni-container flex-space-between how-vertical-center">
                <SlideShow />
                <div className="uni-col about-col">
                    <div className="emp-howto-content">
                        <div className="uni-h-wrap how-it-works-h">
                            <h2 className="align-left">How it works</h2>
                        </div>
                        <div className="uni-subh-wrap">
                            <p className="align-left">Sunset is easy to use on any smartphone. Login and begin tackling your daily tasks.</p>
                            <br />
                        </div>
                        <h4 className="emp-h4">1. Sign up today</h4>
                        <p className="emp-p">Download the app, create an account, and get started on your journey to a healthier and happier life.</p>
                        <h4 className="emp-h4">2. Take a burnout assessment</h4>
                        <p className="emp-p">Identify how isolation and burnout impact you. Your answers are anonymous.</p>
                        <h4 className="emp-h4">3. Receive a personalized treatment plan</h4>
                        <p className="emp-p">On-demand help anytime you need it. Designed by experts, our treatment plans consist of real-life actions you can take daily.</p>
                        <h4 className="emp-h4">4. Take the actions that work</h4>
                        <p className="emp-p">Watch your recovery over time. Learn what actions are helping you recover the most.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="uni-section border">
            <div className="uni-container flex-vertical-center">
                <h3 className="uni-h3 product-footer-h3">Try out Sunset for free</h3>
                <p className="uni-h4 product-footer-h4">Download the Sunset app to improve your mental wellness today</p>
                <div className="uni-product-footer-apps">
                  <OutboundLink href="https://apps.apple.com/us/app/sunset-health/id1490337966?itsct=apps_box&amp;itscg=30200" 
                    className="ios-app-container">
                      <img 
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1576108800&h=f58936e21f0e752c8202144c8bced4b7" 
                        alt="Download on the App Store" 
                        className='uni-product-ios-app'
                      />
                  </OutboundLink>
                  <OutboundLink href='https://play.google.com/store/apps/details?id=com.sunsetfrontend&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                      style={{
                        display: 'inline-block', 
                        overflow: 'hidden', 
                      }}
                  >
                    <img 
                      alt='Get it on Google Play' 
                      src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                      className="uni-product-android-app"
                      />
                  </OutboundLink>
                </div>
            </div>
        </section>
      </Layout>
    )
}

export default ProductPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
